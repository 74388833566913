$(document).ready(function () {

    var home = document.getElementsByTagName('base')[0].href;

    if( $('#dateStart').length > 0 ){
        $('.datepicker').pikaday({ firstDay: 1 });
    }

    $('.newPupilModal').click(function () {
        var class_id = $(this).attr('data-classid');
        $modal = $('#pupil');

        $.get( home + '/addPupil/' + class_id, function (data) {
            // console.log( data );
            $('.modal-content', $modal).html( data );
            $modal.modal('show');
        });
    });

    $('.editPupilModal').click(function () {
        var pupil_id = $(this).attr('data-pupilid');
        $modal = $('#pupil');

        $.get( home + '/editPupil/' + pupil_id, function (data) {
            //console.log( data );
            $('.modal-content', $modal).html( data );
            $modal.modal('show');
        });
    });

    if( $('body').hasClass('start') ){
        setCellsSameHeight();
    }

    function setCellsSameHeight(){
        $('.sameHeight').css( 'height', '' );
        $('.sameHeight2').css( 'height', '' );

        var max = $('.sameHeight:nth-child(1)').outerHeight();
        for( $i = 2; $i <= 3; $i++ ){
            if( $('.sameHeight:nth-child('+ $i +')').outerHeight() > max ){
                max = $('.sameHeight:nth-child('+ $i +')').outerHeight();
            }
        }
        $('.sameHeight').css( 'height', max );

        if( $(window).width() > 500 ){
            max = $('.sameHeight2:nth-child(1)').outerHeight();
            for( $i = 2; $i <= 3; $i++ ){
                if( $('.sameHeight2:nth-child('+ $i +')').outerHeight() > max ){
                    max = $('.sameHeight2:nth-child('+ $i +')').outerHeight();
                }
            }

            if( $(window).width() <= 780 ){ max = max * 1.8; }

            $('.sameHeight2').css( 'height', max );
        }
        else{
            for( $i = 1; $i <= 3; $i++ ){
                if( $('.sameHeight2:nth-child('+ $i +')').outerHeight() > max ){
                    max = $('.sameHeight2:nth-child('+ $i +')').outerHeight();
                }
            }
        }

    }

    $('.filterButton').click(function () {
       var value = $(this).attr('href').split('#')[1];

       $('.filterButton').removeClass('active');
       $(this).addClass('active');

       if( value == "all" ){
           $('.schoolslist .school').fadeIn();
       }
       else{
           $('.schoolslist .school').not('.schoolslist .school.'+value).fadeOut();
           $('.schoolslist .school.'+value).fadeIn();
       }

        return false;
    });

    $(window).resize(function () {
        setCellsSameHeight();
    });

    if( $('.schoolslist').length > 0 ){
        var empty = $('.schoolslist .school.empty').length;
        var partly = $('.schoolslist .school.partly').length;
        var complete = $('.schoolslist .school.complete').length;



        $('.currentEmpty').html( '('+ empty +')' );
        $('.currentPartly').html( '('+ partly +')' );
        $('.currentComplete').html( '('+ complete +')' );
    }
});